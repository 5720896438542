@import 'styles/variables';

.container {
  &Main {
    @media (max-width: map-get($grid-breakpoints, 'xl')) {
      grid-template-columns: repeat(2, 1fr) !important;
    }

    @media (max-width: map-get($grid-breakpoints, 'lg')) {
      grid-template-columns: 1fr !important;
    }
  }
}

.buttons {
  padding: 52px 0;

  a {
    border-radius: 30px;
    border: 2px solid;
    font-weight: 700;

    padding: 10px 22px;
    font-size: 12px;

    @media (min-width: map-get($grid-breakpoints, 'sm')) {
      padding: 12px 34px;
      font-size: 16px;
    }

    @media (min-width: map-get($grid-breakpoints, 'md')) {
      padding: 12px 50px;
      font-size: 16px;
    }

    @media (min-width: map-get($grid-breakpoints, 'lg')) {
      padding: 16px 56px;
      font-size: 20px;
    }

    transition: background-color 0.3s ease-in-out,
    color 0.3s ease-in-out,
    border-color 0.3s ease-in-out;

    &.active {
      cursor: default;
    }

    &:nth-child(1) {
      background-color: $white;
      color: $premiumDark;
      border-color: $premiumDark;

      &.active {
        background-color: $premiumDark;
        color: $white;

        &:hover {
          background-color: $premiumDark;
        }
      }

      &:hover {
        background-color: lighten($premiumDark, 10%);
        border-color: lighten($premiumDark, 10%);
        color: $white;
      }
    }

    &:nth-child(2) {
      background-color: $white;
      color: $orange500;
      border-color: $orange500;

      &.active {
        background-color: $orange500;
        color: $white;

        &:hover {
          background-color: $orange500;
        }
      }

      &:hover {
        background-color: lighten($orange500, 10%);
        border-color: lighten($orange500, 10%);
        color: $white;
      }
    }
  }

  @media (max-width: map-get($grid-breakpoints, 'lg')) {
    padding: 16px 0;
    row-gap: 8px;
    justify-content: center;

    button {
      font-size: 12px;
      padding: 4px;
    }
  }
}