@import 'styles/variables';

.header {
  background-color: $premiumDark;

  h1 {
    color: $white;
    text-align: center;
    margin-bottom: 24px;
    font-size: 21px;
    font-weight: 700;
  }

  @media (min-width: map-get($grid-breakpoints, 'lg')) {
    padding: 24px 0 76px;

    h1 {
      text-align: start;
      margin-bottom: 44px;
      font-size: 36px;
    }

    &.margin {
      margin-bottom: 24px;
    }
  }

  &.margin {
    margin-bottom: 60px;
  }
}

.slider {
  padding-bottom: 10px;

  .slideWrapper {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  // :global(.slick-slide) {
  //   > div > div {
  //     margin: 0 auto;
  //     @media (min-width: map-get($grid-breakpoints, 'lg')) {
  //       margin: 0 12px;
  //     }
  //   }
  // }
  :global(.slick-arrow) {
    position: absolute;
    width: 24px;
    height: 24px;
    z-index: 10;
    top: 50%;

    &::before {
      font-size: 24px;
      color: $orange500;
      opacity: 1;
    }

    &:global(.slick-prev) {
      left: 0 !important;

      transform: translateX(-30%);
    }

    &:global(.slick-next) {
      right: 0 !important;
      transform: translateX(30%);
    }
  }

  :global(.slick-dots) {
    position: absolute;
    left: 50%;
    top: 95%;
    transform: translateX(-50%);

    button::before {
      color: $orange200;
      opacity: 0.5;
      width: 8px;
      height: 8px;
      font-size: 8px;
      transition: all 0.5s;
    }

    li:global(.slick-active)>button::before {
      color: $orange500;
      opacity: 1;
    }
  }
}