@import 'styles/variables';

$edgeBlurHeight: 5%;
$backdropBrightness: 0.2;
$backdropBlur: 10px;
$textContainerHeight: 45%;
$textContainerHeightMobile: 55%;
$topTransparency: 1;
$bottomTransparency: 0.2;

.videoBlock {
  height: 300px;
  width: 80%;
  position: relative;
  overflow: hidden;
  border-radius: 24px;
  border: 4px solid;
  margin: 0 auto;
  background-size: cover;    
  background-position: center; 
  background-color: rgba(90, 90, 90);
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur($backdropBlur) brightness($backdropBrightness);
  }
  .img {
    width: 100%;
    max-height: 100% - $textContainerHeightMobile;
    object-fit: cover;
    position: relative;
    mask-image: linear-gradient(to bottom, rgba(0,0,0,$topTransparency) 100% - $edgeBlurHeight, rgba(0,0,0,$bottomTransparency));
  }

  &.header {
    border-color: $orange500;
  }

  &.main {
    border-color: $premiumDark;
  }

  .footer {
    width: 30rem;
    min-height: $textContainerHeightMobile;
    position: absolute;
    display: flex;
    flex-direction: column;
    z-index: 1;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 8px 12px 16px;
    color: $white;

    .duration {
      color: $white;
      font-size: 10px;
      font-weight: 200;
      margin-bottom: 7px;
    }

    .title {
      color: $white;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      margin-bottom: 14px;
      // max-width: 350px;
    }

    .play {
      position: absolute;
      top: 0;
      right: 8%;
      transform: translateY(-50%);
      width: 36px;
      height: 36px;
      border-radius: 50%;
      background-color: $orange500;
    }

    .author {
      margin-top: auto;
      div,
      p {
        font-size: 10px !important;
        @media (min-width: map-get($grid-breakpoints, 'sm')) {
          font-size: 12px !important;
        }
      }
    }
  }

  @media (min-width: map-get($grid-breakpoints, 'lg')) {
    max-width: 530px;
    height: 350px;
    border: 2px solid;
    .footer {
      .duration {
        font-size: 13px;
        margin-bottom: 20px;
      }
      .title {
        font-size: 14px;
        margin-bottom: 20px;
      }
      .play {
        width: 68px;
        height: 68px;
      }
    }
  }

  @media (min-width: map-get($grid-breakpoints, 'xxl')) {
    .img {
      max-height: 100% - $textContainerHeight;
    }
    .footer {
      min-height: $textContainerHeight;
    }
  }
  @media (min-width: map-get($grid-breakpoints, 'xxxl')) {
    min-width: 450px;
    height: 520px;
    .footer {
      padding: 20px 24px 32px;
      .title {
        font-size: 20px;
      }
    }
  }
}
